<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent>
    <v-form v-model="valid" ref="formManifDestinatario" lazy-validation>
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text">Manifestação do Destinatário</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field label="Chave da Nota" v-model="nota.chave" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Tipo de Manifestação" v-model="descricaoTipo" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea label="Justificativa" v-model="manifestacao.justificativa" :rules="validarDescricao"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar()">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="enviar()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constantes from '@/util/constantes';

export default {
  props: ['dialog', 'tipo', 'nota', 'idDestinada'],

  data: () => ({
    valid: false,
    validarDescricao: [(v) => (v && v.length > 15) || 'Descrição deve conter pelo menos 15 caracteres'],
    manifestacao: {
      idDestinada: '',
      tpEvento: '',
      chaveNfe: '',
      justificativa: '',
    },
  }),

  computed: {
    descricaoTipo() {
      if (this.tipo == constantes.MANIF_CONFIRMACAO) {
        return 'Confirmação da Operação';
      } else if (this.tipo == constantes.MANIF_CIENCIA) {
        return 'Ciência da Operação';
      } else if (this.tipo == constantes.MANIF_DESCONHECIMENTO) {
        return 'Desconhecimento da Operação';
      } else if (this.tipo == constantes.MANIF_NAO_REALIZADA) {
        return 'Operação não Realizada';
      }
    },
  },

  methods: {
    fechar() {
      this.$emit('fechar');
      this.limpar();
    },

    enviar() {
      if (this.$refs.formManifDestinatario.validate()) {
        this.manifestacao.idDestinada = this.idDestinada;
        this.manifestacao.tpEvento = this.tipo;
        this.manifestacao.chaveNfe = this.nota.chave;
        this.$emit('enviarManifestacao', this.manifestacao);
        this.limpar();
      }
    },

    limpar() {
      this.manifestacao = {
        tpEvento: '',
        chaveNfe: '',
        justificativa: '',
      };
    },
  },
};
</script>
